import { inView, animate } from 'motion';
import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect';
import './main.css';
import Navigation from './Blocks/Header/navigation';
import CountUp from './Blocks/CountUpItem/countUp';
import 'blaze-slider/dist/blaze.css';
import SingleProduct from './Blocks/SingleProduct/SingleProduct.js';

Alpine.plugin(intersect);

window.Alpine = Alpine;
Alpine.start();


const projectLists = document.querySelectorAll('.giantpeach-gallerygrid');
if (projectLists.length) {
  import('./Blocks/GalleryGrid/GalleryGrid.js').then(({ default: GalleryGrid }) => {
    projectLists.forEach(el => {
      GalleryGrid(el);
    });
  });
}

const domReady = () => {
  Navigation();
  CountUp();
  const bannerSlider = document.querySelectorAll('.banner-slider');
  let transitionableBlocks = document.querySelectorAll('.transition-block');

  document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
        
  if (bannerSlider.length) {
    import('./Blocks/BannerSlider/bannerSlider.js').then(({ default: BannerSlider }) => {
      BannerSlider();
    });
  }

  const productCarousel = document.querySelectorAll('.main-product-carousel');

if(productCarousel.length){
  import('./Blocks/SingleProduct/SingleProduct.js').then(({default: SingleProduct}) => {
    productCarousel.forEach(el => {
      SingleProduct(el);
    })
  })
}

  let inViewStop = inView(transitionableBlocks, ({ target }) => {
    animate(
      target,
      { opacity: 1, transform: 'none' },
      { delay: 0.2, duration: 0.5, easing: [0.25, 0.1, 0.25, 1] },
    );

    return () => {
      animate(
        target,
        { opacity: 0, transform: 'translateY(32px)' },
        { duration: 0.5, easing: [0.25, 0.1, 0.25, 1] },
      );
    };
  });

  document.addEventListener('block:load', () => {
    transitionableBlocks = document.querySelectorAll('.transition-block');
    inViewStop();
    inViewStop = inView(transitionableBlocks, ({ target }) => {
      animate(
        target,
        { opactiy: 1, transform: 'none' },
        { delay: 0.2, duration: 0.5, easing: [0.25, 0.1, 0.25, 1] },
      );

      return () => {
        animate(
          target,
          { opactiy: 0, transform: 'translateY(32px)' },
          { duration: 0.5, easing: [0.25, 0.1, 0.25, 1] },
        );
      };
    });
  });
};

document.addEventListener('DOMContentLoaded', domReady);
