import Flickity from 'flickity';
import 'flickity/dist/flickity.min.css';
import 'flickity-as-nav-for';

const SingleProduct = section => {
    if (document.querySelector('.main-product-carousel')) {
        var options = {
        accessibility: true,
        prevNextButtons: true,
        pageDots: false,
        imagesLoaded: true,
        };

        var navOptions = {
        asNavFor: '.main-product-carousel',
        draggable: false,
        groupCells: '100%',
        pageDots: false,
        prevNextButtons: false,
        cellAlign: 'left'
        };

        var mainCarousel = document.querySelectorAll('[data-mainproductcarousel]');
        var productNavCarousel = document.querySelectorAll('[data-productNavCarousel]');

        mainCarousel.forEach((mainCarousel) => new Flickity(mainCarousel, options));
        productNavCarousel.forEach((productNavCarousel) => new Flickity(productNavCarousel, navOptions));
    }
}

export default SingleProduct;