import { animate, stagger, timeline } from 'motion';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import throttle from 'lodash.throttle';

const Navigation = () => {
  const navBar = document.getElementById('page-navbar');
  const navEl = document.querySelector('[x-data="menu"]');
  const navItemEls = navEl.querySelectorAll('.nav-item');
  const searchBar = document.getElementById('searchBar');

  if (!navEl) {
    return;
  }

  // Navbar hide on scroll
  let lastScrollTop = 0;
  window.addEventListener('scroll', throttle( () => {
    const scrollTop = window.scrollY;

    // After 60px translate/top / hide on scroll down
    if (scrollTop >= 60) {
      if (scrollTop >= lastScrollTop) {
        navBar.classList.remove('!transform-none');
        navBar.classList.add('-translate-y-full');

        document.dispatchEvent(new Event('closeSearch'));
        closeSearch();

      } else {
        navBar.classList.add('!transform-none');
      }

      lastScrollTop = scrollTop;
    } else {
      navBar.classList.remove('!transform-none');            
      navBar.classList.remove('-translate-y-full');
    }
  }, 100));

  document.addEventListener('nav:toggle', (e) => {
    if (e.detail) {
      openNav();
    } else {
      closeNav();
    }
  });

  document.addEventListener('search:toggle', (e) => {
    if (e.detail) {
      openSearch();
    } else {
      closeSearch();
    }
  });

  const openSearch = () => {
    searchBar.classList.add('!opacity-100', '!visible');

    setTimeout(_=> searchBar.querySelector('input[name="s"]').focus(), 100);
  };

  const closeSearch = () => {
    searchBar.classList.remove('!opacity-100', '!visible');
  }

  const openNav = () => {
    disableBodyScroll(navEl.querySelector('.scroll-list'));

    timeline([
      [navEl, { y: 0 }, { duration: 0.5, easing: [0.25, 0.1, 0.25, 1] }],
      [
        navItemEls,
        { opacity: 1 },
        { duration: 0.3, delay: stagger(0.1), easing: [0.25, 0.1, 0.25, 1], at: '-0.2' },
      ],
    ]);
  };

  const closeNav = (duration = 0.3) => {
    enableBodyScroll(navEl.querySelector('.scroll-list'));

    timeline([
      [
        navEl,
        { y: '-100%' },
        { duration: duration, easing: [0.25, 0.1, 0.25, 1] },
      ],
      [
        navItemEls,
        { opacity: 0 },
        {
          duration: duration,
          easing: [0.25, 0.1, 0.25, 1],
        },
      ],
    ]);
  };

  closeNav(0);
};

export default Navigation;
